import './Game.scss'

import Slide1 from './slides/1'
import Slide2 from './slides/2'
import Slide3 from './slides/3'
import Slide4 from './slides/4'

import { $t } from '@/i18n/i18n'

import DelayedImage from '@/components/UI/Image/DelayedImage'

import cloudsImage from './assets/clouds.png'
import cloudsPlImage from './assets/clouds-placeholder.png'
import blurImage from './assets/blur.png'
import blurPlImage from './assets/blur-placeholder.png'
import background2Image from './assets/background-2.png'


const Game = () => {
    return (
        <div className='game'>
            <div className='game__wrapper'>
                <div className='game__title'>
                    <h2>{ $t('pages.home.game.title') }</h2>
                </div>
                <div className='game__content'>
                    <div className='content__slides'>
                        <Slide1/>
                        <Slide2/>
                        <Slide3/>
                        <Slide4/>
                    </div>
                </div>
            </div>
            <div className='game__background'>
                    <div className='background__4'></div>
                    <div className='background__3'></div>
                    <div className='background__2'>
                        <DelayedImage src={ background2Image } placeholder={ blurPlImage } width="1440" height="800"/>
                    </div>
                    <div className='background__blur'>
                        <DelayedImage src={ blurImage } placeholder={ blurPlImage } width="1800" height="670"/>
                    </div>
                    <div className='background__clouds'>
                        <DelayedImage src={ cloudsImage } placeholder={ cloudsPlImage } width="1440" height="795"/>
                    </div>
            </div>
        </div>
    )
}

export default Game