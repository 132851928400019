import './FAQDonut.scss'
import { $t } from '@/i18n/i18n'
import cn from 'classnames'
import Donut from '../../../../Home/components/Tokenomics/Donut'


const FAQDonut = () => {

    return (
        <div className='FAQDonut'>
            <Donut label="total" className='FAQDonut__total'/>
            <div className='total-supply'>
                <h6>{ $t('pages.home.tokenomics.stats.total.total_supply.text') }</h6>
                <p>{ $t('pages.home.tokenomics.stats.total.total_supply.subtext') }</p>
            </div>
        </div>
    )
}

export default FAQDonut