import * as THREE from 'three';
import { utils } from '../utils'
import { layers } from '../constants';
import { getMouseCoords } from '@/animator/js/renderer'

const assets = [
    require('../../assets/star-blue.png'),
    require('../../assets/star-green.png'),
    require('../../assets/star-white.png'),
    require('../../assets/star-yellow.png'),
]

class Mouse {
    instance
    material
    geometry

    count = 200
    parallelDataAttribute = []
    parallelObject = {}

    constructor( scene ) {
        this.geometry = this.generateBufferGeometry()
        this.shader = shader()
        this.material = this.setMaterial(this.shader)
        this.instance = new THREE.Points(this.geometry, this.material)
        this.instance.layers.enable( layers.BLOOM_SCENE )
        this.instance.position.set(0, 0, 0)
        scene.add( this.instance )
    }

    generateBufferGeometry() {
        const geometry = new THREE.BufferGeometry()

        const pointPositions = [];
        const pointSizes = [];
        const pointMap = []
        const alpha = []

        for (let i = 0; i < this.count; i++) {
            this.parallelDataAttribute.push({ 
                to: utils.rMinMax(0.005, 0.02),
                tx: utils.rMinMax(-0.001, 0.001),
                ty: utils.rMinMax(-0.001, 0.001), 
                tz: utils.rMinMax(-0.001, 0.001), 
            })
            this.parallelObject[i] = null
            pointPositions.push(0, 0, 0)
            pointSizes.push(utils.rMinMax(20, 80))
            pointMap.push( Math.round(utils.rMinMax(0, assets.length - 1)) )
            alpha.push(0)
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(pointPositions, 3));
        geometry.setAttribute('size', new THREE.Float32BufferAttribute(pointSizes, 1));
        geometry.setAttribute('mapId', new THREE.Float32BufferAttribute(pointMap, 1));
        geometry.setAttribute('alpha', new THREE.Float32BufferAttribute(alpha, 1));
        return geometry
    }

    setMaterial(shader) {
        return new THREE.ShaderMaterial({
            vertexShader: shader.vertexShader,
            fragmentShader: shader.fragmentShader,
            uniforms: shader.uniforms,
            defines: Object.assign({}, shader.defines),
            transparent: true,
            depthWrite: false,
            depthTest: false,
            blending: THREE.AdditiveBlending,
        });
    }

    resize() {
        this.material.uniforms.iResolution.value.x = window.innerWidth * window.devicePixelRatio
        this.material.uniforms.iResolution.value.y = window.innerHeight * window.devicePixelRatio
    }

    mousemove() {
        for (let i = 0; i < this.count; i++) {
            if ( this.parallelObject[i] === null ) {
                this.parallelObject[i] = { ...this.getSceneMousePosition(), inited: false }
                return
            }
        }
    }

    mouseclick() {
        const max = 5
        let count = 0
        for (let i = 0; i < this.count; i++) {
            if ( this.parallelObject[i] === null ) {
                this.parallelObject[i] = { ...this.getSceneMousePosition(), inited: false }
                count++
            }
            if ( count > max ) { return }
        }
    }

    getSceneMousePosition() {
        const coord = getMouseCoords().window
        return new THREE.Vector3(
            coord.x / window.innerWidth * 2 - 1,
            coord.y / window.innerHeight * -2 + 1,
            0
        )
    }


    render() {
        for (let i = 0; i < this.count; i++) {
            if ( this.parallelObject[i] !== null ) {
                const _ = this.parallelObject[i]

                const alpha = this.geometry.attributes.alpha.getX(i)
                const x = this.geometry.attributes.position.getX(i)
                const y = this.geometry.attributes.position.getY(i)
                const z = this.geometry.attributes.position.getZ(i)

                if ( !_.inited ) {
                    this.geometry.attributes.position.setXYZ(i, _.x, _.y, _.z)
                    this.geometry.attributes.alpha.setX(i, 1)
                    _.inited = true
                } else {
                    const newalpha = alpha - this.parallelDataAttribute[i].to
                    if ( newalpha < 0 ) { 
                        this.parallelObject[i] = null
                        this.geometry.attributes.alpha.setX(i, 0)
                        this.geometry.attributes.position.setXYZ(i, 0, 0)
                    } else {
                        this.geometry.attributes.position.setXYZ(i, 
                            x + this.parallelDataAttribute[i].tx,
                            y + this.parallelDataAttribute[i].ty, 
                            z + this.parallelDataAttribute[i].tz
                        )
                        this.geometry.attributes.alpha.setX(i, newalpha)
                    }
                }
            }
        }
        this.geometry.attributes.alpha.needsUpdate = true
        this.geometry.attributes.position.needsUpdate = true
    }
}

const shader = () => {
    return {
        uniforms: {
            iMap: { value: assets.map(img => new THREE.TextureLoader().load(img)) },
            iResolution: {
                value: {
                    x: window.innerWidth * window.devicePixelRatio,
                    y: window.innerHeight * window.devicePixelRatio
                }
            },
        },
        vertexShader: `
            attribute float mapId;
            attribute float size;
            attribute float alpha;
            uniform vec2 iResolution;
            varying vec2 vUv;
            varying float id;
            varying float vAlpha;
    
            void main() {
                vUv = uv;
                // vUv = 2. * uv - 1.;

                vAlpha = alpha;
                id = mapId;
                vec3 v = position;
                vec4 vpos = modelViewMatrix * vec4(v, 1.);
                gl_PointSize = size * iResolution.y / 1000. / -vpos.z;
                gl_Position = vec4(v, 1.);
            }`,
        fragmentShader: `
            uniform sampler2D iMap[${assets.length}];
            varying vec2 vUv;
            varying float id;
            varying float vAlpha;

            vec4 getTexColor(float id) {
                if ( id == 0. ) {
                    return texture2D( iMap[0], gl_PointCoord );
                }
                if ( id == 1. ) {
                    return texture2D( iMap[1], gl_PointCoord );
                }
                if ( id == 2. ) {
                    return texture2D( iMap[2], gl_PointCoord );
                }
                if ( id == 3. ) {
                    return texture2D( iMap[3], gl_PointCoord );
                }
            }

            void main() {
                vec4 texColor = getTexColor(id);
                if (texColor.a < 0.1) { discard; }
                gl_FragColor = vec4(texColor.xyz, texColor.a * vAlpha );
            }`
    }
}


export default Mouse