import './Modals.scss'
import { Transition } from 'react-transition-group';
import CalculatorModal from './Calculator/CalculatorModal'
import { Children, cloneElement, useRef, useMemo, useContext } from 'react';
import { ModalsContext } from './context';
import { modals } from './constants';

const modalContainer = {
    duration: 500,
    defaultStyle: {
        transition: `opacity ${500}ms ease-in-out, transform ${500}ms ease-in-out`,
        opacity: 0,
        transform: 'translateY(10rem)'
    },
    transitionStyles: {
        entering: { opacity: 1, transform: 'none' },
        entered: { opacity: 1, transform: 'none' },
        exiting: { opacity: 0, transform: 'translateY(10rem)' },
        exited: { opacity: 0, transform: 'translateY(10rem)' },
    }
}


const ModalContainer = ({ id, children }) => {
    const { showModal } = useContext(ModalsContext)
    // eslint-disable-next-line
    const show = useMemo(() => id === showModal, [showModal])
    const nodeRef = useRef(null);

    return (
        <div className='modal-container'>
            <Transition nodeRef={nodeRef} in={show} timeout={modalContainer.duration}>
                {
                    state => Children.map(children, child => (
                        cloneElement(child, {
                            ref: nodeRef,
                            style: {
                                ...modalContainer.defaultStyle,
                                ...modalContainer.transitionStyles[state]
                            }
                        })
                    ))
                }
            </Transition>
        </div>
    )
}

const modalLayout = {
    duration: 500,
    defaultStyle: {
        transition: `opacity ${500}ms, visibility ${500}ms`,
        opacity: 0,
        visibility: 'hidden'
    },
    transitionStyles: {
        entering: { opacity: 1, visibility: 'visible' },
        entered: { opacity: 1, visibility: 'visible' },
        exiting: { opacity: 0, visibility: 'hidden' },
        exited: { opacity: 0 , visibility: 'hidden'},
    }
}


const Modals = () => {
    const { showModal, setModal } = useContext(ModalsContext)
    // eslint-disable-next-line
    const show = useMemo(() => (showModal !== modals.NONE) && Object.values(modals).includes(showModal), [showModal])
    const nodeRef = useRef(null);
    return (
        <Transition nodeRef={nodeRef} in={show} timeout={modalLayout.duration}>
            {state =>
                <div 
                    className="modals"
                    ref={nodeRef} 
                    style={{
                        ...modalLayout.defaultStyle,
                        ...modalLayout.transitionStyles[state]
                    }}
                >
                    <ModalContainer id={modals.CALCULATOR}>
                        <CalculatorModal />
                    </ModalContainer>
                    <div onClick={() => setModal(modals.NONE)} className='modals__background'></div>
                </div>
            }
        </Transition>
    )
}

export default Modals