import "./Button.scss"
import Icon from "@/components/UI/Icon/Icon"
import cn from 'classnames'

const Button = ({ label, className, children, link, ...props }) => {
  switch (label) {
    case 'burger':
      return (
        <div {...props} className={cn("burger", className)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )
    case 'metamask':
      return (
        <a className={`button-container button-${label} ${props.disabled ? '-disabled' : ''}`}>
          <button {...props} className={cn(`button`, className)}>
            <img src={require('./assets/metamask.png')}></img>
            <span>{children}</span>
          </button>
        </a>
      )
    default:
      return (
        <a className={`button-container button-${label} ${props.disabled ? '-disabled' : ''}`} href={link} target="_blank" rel="noreferrer">
          <button {...props} className={cn(`button`, className)}>
            <Icon label={label} />
            <span>{children}</span>
          </button>
        </a>
      );
  }
};

export default Button;
