import { createContext, useState } from "react";
import { modals } from "./constants";

export const ModalsContext = createContext()

const ModalsContextProvider = ({ children }) => {
    const [ showModal, setShowModal ] = useState( modals.NONE )

    function setModal( modal ) {
        const modalsValues = Object.values( modals )
        if ( modalsValues.includes( modal ) ) {
            setShowModal( modal )
            return
        }
        console.error(`[Modals]: no modal ${modal} found`)
    }

    const value = {
        modals,
        showModal, setModal 
    }

    return (
        <ModalsContext.Provider value={value}>
            { children }
        </ModalsContext.Provider>
    )
}

export default ModalsContextProvider

