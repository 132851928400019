import './TreeNFTs.scss'
import cn from 'classnames'
import { $t } from '@/i18n/i18n'
import { useState, React } from 'react'
import Card from './Card/Card'
import LegendaryImage from './Card/assets/legendary 1 7.png'
import EpicImage from './Card/assets/Epic.png'
import RareImage from './Card/assets/Rare.png'
import CommonImage from './Card/assets/common 3 2.png'
import { rarity } from '../constants'
import EmptyCard from './EmptyCard/EmptyCard'
import {cardsMock} from './TreeNTSMock/cardsMock'
import { map } from 'lodash'

const TreeNFTs = ({...props}) => {


    const cardsLength = cardsMock.length
    console.log(cardsLength)


    return (
        <div className='tree-nfts'>
            <div className='tree-nfts__title'>
                <p>
                    { $t('pages.myspace.title') }
                </p>
                <div className='tree-nfts__title__image'>
                    <span>4</span>
                </div>
            </div>
            <div className='tree-nfts__cards'>
                {cardsMock.map((_, i) => (
                    <Card tag={_[0]} cardNumber={_[1]} image={_[2]} rarity={_[3]} delayedImage={_[4]}></Card>
                ))}
                <EmptyCard/>
                <EmptyCard/>
                <EmptyCard/>
            </div>
        </div>
    )
}

export default TreeNFTs