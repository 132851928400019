import './CustomRange.scss'
import { Range, getTrackBackground } from 'react-range'
import { useState, useEffect } from 'react';

const CustomRange = ({ value = 18, min = 0, max = 24, step = 1, onChange }) => {
    const [ values, setValues ] = useState([value])
    useEffect(() => onChange(values), [ values, onChange ])
    return (
        <div className='calculator-range'>
            <Range
                step={step}
                min={min}
                max={max}
                values={values}
                onChange={(values) => setValues(values)}
                renderTrack={({ props, children }) => (
                    <div 
                        className='calculator-track' 
                        {...props}
                        style={{
                            background: getTrackBackground({
                                min, max, values,
                                colors: [ '#24A87C', '#22222F' ]
                            })
                        }}
                    >
                        {children}
                    </div>
                )}
                renderMark={({ props, index }) => (
                    <div className='range-mark' {...props}>
                        { index % 2 === 0 ? index : '' }
                    </div>
                )}
                renderThumb={({ props, value }) => (
                    <div {...props} className='calculator-thumb'>
                        <div data-value={value} />
                    </div>
                )}
            />
        </div>
    )
}

export default CustomRange