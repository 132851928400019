import * as THREE from 'three';
import { utils } from '../utils'
import { layers } from '../constants';

const assets = [
    require('../../assets/star-blue.png'),
    require('../../assets/star-green.png'),
    require('../../assets/star-white.png'),
    require('../../assets/star-yellow.png'),
]

class BG {
    instance
    material
    geometry

    count = 300
    parallelDataAttribute = []

    constructor( scene ) {
        this.geometry = this.generateBufferGeometry()
        this.shader = shader()
        this.material = this.setMaterial(this.shader)
        this.instance = new THREE.Points(this.geometry, this.material)
        this.instance.layers.enable( layers.ENTIRE_SCENE )
        this.instance.position.set(0, 0, 0)
        scene.add( this.instance )
    }

    generateBufferGeometry() {
        const geometry = new THREE.BufferGeometry()

        const pointPositions = [];
        const pointSizes = [];
        const pointMap = []
        const alpha = []


        for (let i = 0; i < this.count; i++) {
            let x = utils.rMinMax(-10, 10);
            let y = utils.rMinMax(0, 5);
            let z = -1;
            this.parallelDataAttribute.push({ aSpeed: utils.rMinMax(-0.03, 0.03) })
            pointPositions.push(x, y, z);
            pointSizes.push(utils.rMinMax(5, 30))
            pointMap.push( Math.round(utils.rMinMax(0, assets.length - 1)) );
            alpha.push( utils.rMinMax(0, 1) );
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(pointPositions, 3));
        geometry.setAttribute('size', new THREE.Float32BufferAttribute(pointSizes, 1));
        geometry.setAttribute('mapId', new THREE.Float32BufferAttribute(pointMap, 1));
        geometry.setAttribute('alpha', new THREE.Float32BufferAttribute(alpha, 1));
        return geometry
    }

    setMaterial(shader) {
        return new THREE.ShaderMaterial({
            vertexShader: shader.vertexShader,
            fragmentShader: shader.fragmentShader,
            uniforms: shader.uniforms,
            defines: Object.assign({}, shader.defines),
            transparent: true,
            depthWrite: false,
            depthTest: false,
            blending: THREE.AdditiveBlending,
        });
    }

    resize() {
        this.material.uniforms.iResolution.value.x = window.innerWidth * window.devicePixelRatio
        this.material.uniforms.iResolution.value.y = window.innerHeight * window.devicePixelRatio
    }

    render() {
        for( let i = 0; i < this.count; i++ ) {
            const alpha = this.geometry.attributes.alpha.getX(i)
            const delta = alpha - this.parallelDataAttribute[i].aSpeed

            if ( delta < 0 || delta > 1 ) {
                this.parallelDataAttribute[i].aSpeed *= -1
            }

            this.geometry.attributes.alpha.setX(i, alpha - this.parallelDataAttribute[i].aSpeed)
        }
        this.geometry.attributes.alpha.needsUpdate = true
    }
}

const shader = () => {
    return {
        uniforms: {
            iMap: { value: assets.map(img => new THREE.TextureLoader().load(img)) },
            iResolution: {
                value: {
                    x: window.innerWidth * window.devicePixelRatio,
                    y: window.innerHeight * window.devicePixelRatio
                }
            },
        },
        vertexShader: `
            attribute float mapId;
            attribute float size;
            attribute float alpha;
            uniform vec2 iResolution;
            varying vec2 vUv;
            varying float id;
            varying float vAlpha;
    
            void main() {
                vUv = uv;
                vAlpha = alpha;
                id = mapId;
                vec3 v = position;
                vec4 vpos = modelViewMatrix * vec4(v, 1.);
                gl_PointSize = size * iResolution.y / 1000. / -vpos.z;
                gl_Position = projectionMatrix * vpos;
            }`,
        fragmentShader: `
            uniform sampler2D iMap[${assets.length}];
            varying vec2 vUv;
            varying float id;
            varying float vAlpha;

            vec4 getTexColor(float id) {
                if ( id == 0. ) {
                    return texture2D( iMap[0], gl_PointCoord );
                }
                if ( id == 1. ) {
                    return texture2D( iMap[1], gl_PointCoord );
                }
                if ( id == 2. ) {
                    return texture2D( iMap[2], gl_PointCoord );
                }
                if ( id == 3. ) {
                    return texture2D( iMap[3], gl_PointCoord );
                }
            }

            void main() {
                // float safeAlpha = clamp(vAlpha, 0., 1.);
                vec4 texColor = getTexColor(id);
                if (texColor.a < 0.1) { discard; }
                gl_FragColor = vec4(texColor.xyz, vAlpha );
            }`
    }
}

export default BG