import './Background.scss'
import Image from '@/components/UI/Image/Image'
import cn from 'classnames'

const Background = ({ image, className, ...props }) => {
    return (
        <div { ...props } className={cn('c-background', className)}>
            <Image src={image} width="1470" height="795" />
        </div>
    )
}

export default Background