import Detector from '../../libs/detector/detector'
import { useEffect, useState } from 'react'

const useDetector = ( dom, hd = (h) => h() ) => {
    const [ to, setTo ] = useState(0)
    const detector = { instance: null }
    const cb = (i) => { hd(setTo)(i) }
    const restart = (dom) => {
        if ( detector.instance ) { detector.instance.unmount() }
        if (!dom.current) { return }
        detector.instance = new Detector(dom.current, cb)
        return () => {if ( detector.instance ) { detector.instance.unmount() }}
    }
    useEffect(() => {
        restart( dom )
    }, [])

    return [ to, setTo, restart ]
}

export { useDetector }