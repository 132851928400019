import './MySpace.scss'
import { $t } from '@/i18n/i18n'
import SwitchBox from '../../components/UI/Forms/Switch/SwitchBox'
import Statistics from './components/Statistics/Statistics'
import { cards } from './components/constants'
import Card from './components/TreeNFTs/Card/Card'
import EarnedImage from './components/Statistics/assets/EarnedImage.png'
import SEQPoolImage from './components/Statistics/assets/SEQPoolImage.png'
import FarmTimeImage from './components/Statistics/assets/FarmTimeImage.png'
import TreeNFTs from './components/TreeNFTs/TreeNFTs'
import whiteBaloonImage from './components/assets/whiteBaloonImage.png'
import Farming from './components/Farming/Farming'
import FAQ from './components/FAQ/FAQ'
import JoinUs from './components/JoinUs/JoinUs'
import Footer from './components/Footer/Footer'
import renderer from '@/animator/js/renderer'
import { useEffect } from 'react'

const MySpace = () => {

    useEffect(() => {
        renderer.startRender()

        return () => renderer.stopRender() 
    }, [])

    return (
        <div id="space" className='my-space'>
            <Farming></Farming>
            <TreeNFTs></TreeNFTs>
            <FAQ></FAQ>
            <JoinUs></JoinUs>
            <Footer></Footer>
        </div>
    )
}

export default MySpace