import './Exchange.scss'
import cn from 'classnames'
import { $t } from '@/i18n/i18n'
import { useState, React } from 'react'

const Exchange = ({...props}) => {


    


    return (
        <div className={cn('exchange', props.className)}>
            <p className='exchange__currency'>
                {props.currency}
            </p>
            <div>
                <p className='exchange__main'>
                    {props.main}
                </p>
                {/* <p className='exchange'>
                    {props.secondary}
                </p> */}
            </div>
        </div>
    )
}

export default Exchange