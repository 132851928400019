import './slides.scss'
import { $t } from '@/i18n/i18n'

import image from '../assets/2.png'
import nftPlaceholderimage from '../assets/2-placeholder.png'
import DelayedImage from '@/components/UI/Image/DelayedImage'


const Slide2 = () => {
    return (
        <div className="slide slide_2">
            <div className='content-border'></div>
            <div className='content__wrapper'>

                <div className='wrapper__text'>
                    <h3>{ $t('pages.home.game.list')[1].title }</h3>
                    <div className='text__row'>
                        <p>{ $t('pages.home.game.list')[1].text }</p>
                    </div>
                </div>

            </div>

            <div className='content__image'>
                <div className='wrapper__image'>
                    <DelayedImage src={ image } placeholder={ nftPlaceholderimage } width="930" height="930" />
                </div>
            </div>
        </div>
    )
}

export default Slide2