import './ScreenScroll.scss'
import ReactTooltip from 'react-tooltip'

import { createContext, useContext, Children, cloneElement, isValidElement, useState, useEffect, useRef } from 'react'

import { useDetector } from '@/animator/js/react/hooks/useDetector'
import Detector from '@/animator/js/libs/detector/detector'

import Tween from '@/animator/js/tween/tween'
import Ease from '@/animator/js/tween/easing'

import { useWHeight } from './hooks/useWHeight'
import { screens } from '@/views/Home/constants'

import { useLocation } from 'react-router-dom'

export const ScreenScrollContext = createContext()

const slides = {}
const getCount = () => Object.keys(slides).length
const state = { changing: false, to: 0, activeScroll: 0 } 
const props = { id: 'screen-id', innerScroll: 'inner-scroll', groupSlides: 'group-slides', slidesCount: 'slides-count', ptip: 'pagination-tip' }

const ScrollProvider = ({ duration = 1000, scrollRoute = '/', children }) => {
    const container = useRef()
    const scroll = useRef()
    const [ wHeight ] = useWHeight()
    const [ active, setActive ] = useState(-1)
    const [ afterChangingActive, setAfterChangingActive ] = useState(-1)
    const [ prevActiveScroll, setPrevActiveScroll ] = useState(0)
    const [ activeScroll, setActiveScroll ] = useState(0)
    const [ slidesCount, setSlidesCount ] = useState(0)
    const [ activeId, setActiveId ] = useState(screens.MAIN)
    const [ prevActive, setPrevActive ] = useState(-1)
    const [ changing, setChanging ] = useState(state.changing)
    const [ to, setTo, restart ] = useDetector(scroll, (h) => { if (!state.changing) { return h }; return () => {} })
    useEffect(() => { state.changing = changing }, [ changing ])
    useEffect(() => { state.to = to }, [ to ])
    useEffect(() => { state.activeScroll = activeScroll }, [ activeScroll ])
    useEffect(() => { if (active === -1) { setActive(0) } }, [])
    const refSlides = useRef({})
    const [ domSlides, setDomSlides ] = useState({})


    // динамика для детектора скролла и позиции скролла
    const location = useLocation()
    const [ leftScrollRoute, setLeftScrollRoute ] = useState(location.pathname !== scrollRoute)
    const [ needDetectorRefresh, setNeedDetectorRefresh ] = useState(false)
    useEffect(() => {
        if (!leftScrollRoute) {
            setLeftScrollRoute(location.pathname !== scrollRoute)
        }
        if (location.pathname === scrollRoute) {
            if (leftScrollRoute) {
                restart(scroll)
                setNeedDetectorRefresh(true)
            }
        }
    }, [ location ])
    useEffect(() => {
        if (!needDetectorRefresh) { return }
        resetScrollPosition()
        setLeftScrollRoute(false)
        setNeedDetectorRefresh(false)
    }, [ needDetectorRefresh ])
    // 


    const value = {
        active, prevActive,
        scroll,
        dispatchActive,
        getCount,
        activeId,
        afterChangingActive,
        container,
        slidesCount, setSlidesCount,
        refSlides,
        changing,
        to, setTo, setChanging,
        domSlides, setDomSlides,
        prevActiveScroll, setPrevActiveScroll,
        activeScroll, setActiveScroll,
    }

    function dispatchActive(id) {
        Object.values(slides).map(_ => {if (_.customId === id) id = _.id })
        if (id !== null && id !== active) {
            setNewActive(id)
        }
    }
    function setNewActive(id) {
        if (slides[active].group !== slides[id].group) {
            setPrevActive(active)
        }
        setAfterChangingActive(-1)
        setPrevActiveScroll(activeScroll)
        setActiveScroll(slides[id].scrollIndex)
        setActiveId(slides[id].customId)
        setActive(id)
        setChanging(true)
        setTimeout(() => {
            setAfterChangingActive(id)
        }, duration)
    }


    useEffect(() => {
        if (to === 0) { return }
        if (!isEnableScroll()) { return }
        const next = active + to
        setNewActive(next)
    }, [ to ])
    function isEnableScroll() {
        if ( changing ) { return false }
        const count = getCount()
        if ( active + to >= count ) { return false }
        if ( active + to < 0 ) { return false }
        return true
    }


    useEffect(() => {
        if ( active < 0 ) { return }
        if ( prevActive < 0 ) { return }
        const from = 100 * prevActiveScroll
        const to = 100 * activeScroll
        Tween.to(from, to, {
            duration,
            renderDelay: 0,
            onChange(context) {
                if (container.current) {
                    container.current.style.transform = `translate3d(0, -${context.value}vh, 0)`
                }
            },
            onComplete(context) {
                if (container.current) {
                    container.current.style.transform = `translate3d(0, -${context.value}vh, 0)`
                }
                setTo(0)
                setTimeout(() => {
                    setChanging(false)
                }, (window.innerWidth > 576) ? 150 : 0)
            },
            ease: Ease.InOutCubic
        })
    }, [ active ])

    useEffect(() => {
        resetScrollPosition()
    }, [ wHeight ])
    function resetScrollPosition() {
        if (changing) { return }
        if ( active < 0 ) { return }
        if (container.current) {
            container.current.style.transform = `translate3d(0, -${100 * slides[active].scrollIndex}vh, 0)`
        }
    }

    return (
        <ScreenScrollContext.Provider value={value}>
            { children }
        </ScreenScrollContext.Provider>
    )
}

const Scroll = ({ children }) => {
    const { scroll } = useContext( ScreenScrollContext )
    
    return (
        <div ref={scroll} className='screen-scroll'>{ children }</div>
    )
}

const Slides = ({ children }) => {
    const { active, activeId, prevActive, container, setSlidesCount, refSlides, setDomSlides, afterChangingActive, setTo, setChanging } = useContext( ScreenScrollContext )
    useEffect(() => { 
        setSlidesCount(getCount())
        setDomSlides(slides)
        // console.log(slides)
    }, [])

    useEffect(() => {
        const parents = Object.values(refSlides.current).map(_ => _.parent)
        const detectors = parents.map(_ => new Detector(_, detectorListener.bind(_)))
        return () => detectors.forEach(_ => _.unmount())
    }, [])
    function detectorListener(to) {
        const self = this
        if (refSlides.current[state.activeScroll].parent !== self) { return } // проверка находимся ли мы на нужном слайде
        const child = self.querySelector('.inner-scroll__content')
        if (!child) { return }
        const parentHeight = self.getBoundingClientRect().height
        const childHeight = child.getBoundingClientRect().height
        if (childHeight <= parentHeight + 10) { return }

        const distance = Number((childHeight - parentHeight).toFixed(0))
        const top = self.scrollTop

        // console.log('innerScroll')
        // console.log('-----> TO', to)
        // console.log('top', top)
        // console.log('distance', distance)

        if ( to === 1 && top < distance - 10 ) { setChanging(true) } 
        else if ( to === -1 && top > 0 + 10 ) { setChanging(true) } 
        else {
            setChanging(false)
            setTo(0)
        }
    }


    let idIndex = 0
    return (
        <div ref={container} className={`screen-scroll__slides -active-id-${activeId}`}>
            {Children.map(children, (child, i) => {
                let slidesCountIndex = 1

                if (isValidElement(child)) {
                    refSlides.current[i] = {}
                    const group = child.props[props.groupSlides] || (child.props[props.id] || idIndex)
                    let slidesCount = child.props[props.slidesCount] || 1
                    slides[idIndex] = { id: idIndex, customId: (child.props[props.id] || idIndex), group, scrollIndex: i, ptip: (child.props[props.ptip] || null) }
                    idIndex++
                    
                    const isInnerScroll = child.props[props.innerScroll] ? true : false

                    while (slidesCountIndex < slidesCount) {
                        slides[idIndex] = { id: idIndex, customId: (child.props[props.id] || idIndex)+`${slidesCountIndex+1}`, group, scrollIndex: i, ptip: (child.props[props.ptip] || null) }
                        slidesCountIndex++
                        idIndex++
                    }

                    const className = `slide slide-${(child.props[props.id] || i)}`
                    let activeClassName = ''
                    let prevActiveClassName = ''
                    let scrollClassName = ''
                    let afterChangingActiveClass = ''

                    if ( active >= 0 ) {
                        activeClassName = `${slides[active].scrollIndex === i ? '-active' : ''}`
                        scrollClassName = `${slides[active].scrollIndex > i ? '-scrolled' : ''}`
                    }
                    if (afterChangingActive >= 0) {
                        afterChangingActiveClass = slides[afterChangingActive].scrollIndex === i ? '-afterchanging' : ''
                    }
                    if ( prevActive >= 0 ) {
                        prevActiveClassName = `${slides[prevActive].scrollIndex === i ? '-prev-active' : ''}`
                    }

                    if ( isInnerScroll ) {
                        return (
                            <div ref={(r) => refSlides.current[i].parent = r} className={`${className} ${activeClassName} ${prevActiveClassName} ${scrollClassName} inner-scroll ${afterChangingActiveClass}`}>
                                <div ref={(r) => refSlides.current[i].child = r} className='inner-scroll__content'>
                                    {cloneElement(child, {
                                        isActive: (active === i)
                                    })}
                                </div>
                            </div>
                        )
                    }

                    return (
                        <div ref={(r) => refSlides.current[i].parent = r} className={`${className} ${activeClassName} ${prevActiveClassName} ${scrollClassName} ${afterChangingActiveClass}`}>
                            {cloneElement(child, {
                                isActive: (active === i)
                            })}
                        </div>
                    )
                }
                return null
            })}
        </div>
    )
}

const Pagination = () => {
    const { active, domSlides, dispatchActive } = useContext( ScreenScrollContext )
    const [ paginations, setPaginations ] = useState( [] )

    useEffect(() => {
        const paginations = []
        Object.values( domSlides ).forEach( _ => {
            if ( !paginations.find(item => item.id === _.group) ) {
                paginations.push( { id: _.group, ptip: _.ptip } )
            }
        })
        setPaginations(paginations)
    }, [ domSlides ])

    const refs = useRef({})
    const showTooltips = () => {
        Object.values(refs.current).forEach(item => ReactTooltip.show(item))
    }
    const hideTooltips = () => {
        Object.values(refs.current).forEach(item => ReactTooltip.hide(item))
    }

    return (
        <div onMouseEnter={showTooltips} onMouseLeave={hideTooltips} className='screen-scroll__pagination'>
            { paginations.map((_, i) => {
                let className = ''
                if ( active >= 0 ) {
                    className = `${slides[active].group === _.id ? '-active' : ''}`
                }

                return (
                    <div className={`pagination-wrapper ${className}`} key={i} onClick={() => dispatchActive(_.id)}>
                        <div 
                            ref={r => refs.current[i] = r} 
                            // onMouseEnter={(e) => e.preventDefault()} 
                            // onMouseLeave={(e) => e.preventDefault()} 
                            data-tip={_.ptip} 
                            data-for={_.id} 
                            className={`pagination-button`}
                        ></div> 
                        <ReactTooltip id={_.id} delayHide={50} place="left" type="light" effect="solid"/>
                    </div>
                )
            })}
            
        </div>
    )
}

export default ScrollProvider
export { Scroll, Slides, Pagination }