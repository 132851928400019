class DetectSwipe {
    #swipe_det = {
        sX: 0,
        sY: 0,
        eX: 0,
        eY: 0
    }
    #directions = Object.freeze({
        UP: 'up',
        DOWN: 'down',
        RIGHT: 'right',
        LEFT: 'left'
    })
    #direction  = 0
    #current    = 0
    #target     = 0
    #element    = null
    #deltaMin
    #cb

    constructor(element, deltaMin, cb) {
        this.#element = element
        this.#deltaMin = deltaMin || 90
        this.#cb = cb || (() => {})
        this.#start()
    }

    get props() {
        return {
            direction: this.#direction,
            current: this.#current,
            target: this.#target,
            element: this.#element,
            deltaMin: this.#deltaMin,
        }
    }

    #start() {
        this.#element.addEventListener('touchstart', this.#touchstart.bind(this), false)
        this.#element.addEventListener('touchmove', this.#touchmove.bind(this), false)
        this.#element.addEventListener('touchend', this.#touchend.bind(this), false)
    }

    destroy() {
        this.#element.removeEventListener('touchstart', this.#touchstart, false)
        this.#element.removeEventListener('touchmove', this.#touchmove, false)
        this.#element.removeEventListener('touchend', this.#touchend, false)
    }

    #touchstart(e) {
        const t = e.touches[0]
        this.#swipe_det.sX = t.screenX
        this.#swipe_det.sY = t.screenY
        this.#swipe_det.eX = this.#swipe_det.sX
        this.#swipe_det.eY = this.#swipe_det.sY
    }
    #touchmove(e) {
        const t = e.touches[0]
        this.#swipe_det.eX = t.screenX
        this.#swipe_det.eY = t.screenY
    }
    #touchend(e) {
        const deltaX = this.#swipe_det.eX - this.#swipe_det.sX
        const deltaY = this.#swipe_det.eY - this.#swipe_det.sY

        if (deltaX ** 2 + deltaY ** 2 < this.#deltaMin ** 2) return
    
        if (deltaY === 0 || Math.abs(deltaX / deltaY) > 1) {
            this.#direction = deltaX > 0 ? this.#directions.RIGHT : this.#directions.LEFT
            this.#current = this.#swipe_det.sX
            this.#target = this.#swipe_det.eX
        } else {
            this.#direction = deltaY > 0 ? this.#directions.UP : this.#directions.DOWN
            this.#current = this.#swipe_det.sY
            this.#target = this.#swipe_det.eY
        }

        if (typeof this.#cb === 'function') { this.#cb({
            direction: this.#direction,
            current: this.#current,
            target: this.#target 
        }) }
        this.#element.dispatchEvent(new CustomEvent('swipe', {
            detail: {
                direction: this.#direction,
                current: this.#current,
                target: this.#target 
            }
        }))
    }

  }

  export default DetectSwipe