const cards = {
    POOL: 'SEQ Pool',
    TIME: 'Farm Time',
    EARNED: 'You Earned'
}

const rarity = {
    LEGENDARY: 'Legendary',
    EPIC: 'Epic',
    RARE: 'Rare',
    COMMON: 'Common'
}

export { cards, rarity }