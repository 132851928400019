import './Genesis.scss'
import { $t } from '@/i18n/i18n'
import Button from '@/components/UI/Button/Button'
import parse from 'html-react-parser'
import LinearBorder from '@/components/Background/LinearBorder'
import { ApiContext } from '@/api/context'
import { useContext } from 'react'
import Icon from '@/components/UI/Icon/Icon'
import { ModalsContext } from '@/components/Modals/context'
import { modals } from '@/components/Modals/constants'
require('./assets/mint.png')

const Genesis = () => {
    const { currency } = useContext(ApiContext)
    const { setModal } = useContext(ModalsContext)
    const ethPrice = currency.eth.price
    return (
        <div className='genesis'>
            <LinearBorder bottom />
            <div className='genesis__slides'>
                <div className='slide slide_1'>

                    <div className='slide__content'>
                        <h2><span className='_move'>{ parse($t('pages.home.genesis.slide_1.title')) }</span></h2>
                        <div className='slide__grid'>
                            { $t('pages.home.genesis.slide_1.list').map((_, i) => (
                                <div key={i} className='grid__content'>
                                    <div>{ '0' + (i+1) }</div>
                                    <h5>{ _.title }</h5>
                                    <p>{ _.text }</p>
                                </div>
                            )) }
                            <div className='calculator'>
                                <p>{ $t('pages.home.genesis.calculator.text') }</p>
                                <Button onClick={ () => setModal( modals.CALCULATOR ) } label="calculator">{ $t('pages.home.genesis.calculator.button') }</Button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='slide slide_2'>
                    <div className='slide__content'>
                        <h2>{ parse($t('pages.home.genesis.slide_2.title')) }</h2>
                        <div className='slide__grid'>
                            { $t('pages.home.genesis.slide_2.list').map((_, i) => {
                                const inEth = ( _.price && ethPrice ) ? `${(Number(_.price) / ethPrice).toFixed(3)}` : ''
                                return (
                                    <div key={i} className='grid__content'>
                                        <div>{ _.categorie }</div>
                                        <h5>{ _.value }
                                            {/* <Icon label={_.n}/> */}
                                        </h5>
                                        <p>{ _.price ? _.price : '' }</p>
                                    </div>
                                )
                            }) }
                        </div>
                    </div>

                </div>
            </div>
            <div className='genesis__background'>
                <div className='background_1'></div>
                <div className='background_2'></div>
            </div>
        </div>
    )
}

export default Genesis