import './Image.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Image = ({ src, width, height, threshold, visibleByDefault, ...props }) => {
    return (
        <LazyLoadImage
            src={src}
            width={width} 
            height={height}
            alt=""
            effect="opacity"
            delayTime={0}
            visibleByDefault={visibleByDefault || false}
            threshold={threshold || 500}
            { ...props }
        />
    )
}

export default Image