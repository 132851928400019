import './Park.scss'
import Image from '@/components/UI/Image/Image'
import landImage from './assets/land.png'
import landPlaceholderImage from './assets/land-placeholder.png'
import { $t } from '@/i18n/i18n'
import parse from 'html-react-parser'
import Icon from '@/components/UI/Icon/Icon'
import LinearBorder from '@/components/Background/LinearBorder'
import Background from '@/components/Background/Background'
import background1Image from './assets/background-1.png'
import background2Image from './assets/background-2.png'
import background3Image from './assets/background-3.png'
import DelayedImage from '@/components/UI/Image/DelayedImage'

const data = [
    require('./assets/1.png'),
    require('./assets/2.png'),
    require('./assets/3.png'),
    require('./assets/4.png'),
    require('./assets/5.png'),
    require('./assets/6.png'),
    require('./assets/7.png'),
    require('./assets/8.png'),
]

const Park = () => {
    return (
        <div className='park'>
            <div className='park__content'>
                <div className='content__header'>
                    <h2>{ $t('pages.home.park.header.title') }</h2>
                    <p>{ parse($t('pages.home.park.header.text')) }</p>
                </div>
                <div className='content__name'>
                    <p className='text'>{ $t('pages.home.park.land.text') }</p>
                    {/* <div className='name__stats'>
                        { $t('pages.home.park.land.stats').map((_, i) => (
                            <div key={i} className="stats__card">
                                <Icon label={_.icon} />
                                <p>{ parse(_.text) }</p>
                            </div>
                        )) }
                    </div> */}
                </div>
                <div className='content__description'>
                    <p className='text'>{ parse( $t('pages.home.park.description.text') ) }</p>
                    <div className='photos'>
                        {data.map((image, i) => (
                            <div key={i} className='photos__preview'>
                                <Image src={image} width="64" height="64" />
                            </div>
                        ))}
                    </div>
                    <p className='subtext'>{ parse( $t('pages.home.park.description.subtext') ) }</p>
                </div>
            </div>
            <div className='park__background'>
                <Background image={ background1Image } className="background_1" />
                <Background image={ background2Image } className="background_2" />
                <Background image={ background3Image } className="background_3" />
                <div className='background__land'>
                    {/* <span>
                        <img src={ landImage } width="500" height="500" alt="" />
                    </span> */}
                    {/* <Image src={ landImage } visibleByDefault={true} width="500" height="500" /> */}
                    <DelayedImage src={ landImage } placeholder={ landPlaceholderImage } width="500" height="500" />
                </div>
                <LinearBorder bottom />
            </div>
        </div>
    )
}

export default Park