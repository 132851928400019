import './SwitchBox.scss'
import cn from 'classnames'
import { $t } from '@/i18n/i18n'
import { useState, React } from 'react'

const MySpace = () => {
    
    const [isChecked, setIsChecked] = useState(false)

    return (
        <div className={cn('switch', isChecked ? '-checked' : '')}>
            <input type="checkbox" className='switch__input' onClick={() => setIsChecked(!isChecked)}/>
            <div className='switch__body'></div>
            <div className='switch__text'>
                <p className={cn('text__on', !isChecked ? '-active' : '')}>
                    { $t('pages.myspace.statistics.switch.on') }
                </p>
                <p className={cn('text__off', isChecked ? '-active' : '')} >
                    { $t('pages.myspace.statistics.switch.off') }
                </p>
            </div>
        </div>
    )
}

export default MySpace