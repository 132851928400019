import * as THREE from 'three'

import { getElementCoords, IgetElementCoords } from '../coords'

class Canvas3d {
    parent
    canvas
    scene
    renderer

    width      = null
    height     = null
    ratio      = null
    rendering  = []
    resizing   = []
    isRenderActive    = false

    checkWindow      = false

    constructor(parent, canvas) {
        this.parent = parent
        this.canvas = canvas

        if (!this.parent || !this.canvas) {
            console.warn(`canvas3d: No needed dom found`)
            this.stopRender()
            return
        }

        this.scene = new THREE.Scene()
        this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true })

        this.startRender()
        this.resize()
    }

    toResize(cb) {
        this.resizing.push(cb)
    }

    stopRender() {
        this.isRenderActive = false
    }

    startRender() {
        this.isRenderActive = true
    }

    toRender(cb) {
        this.rendering.push(cb)
    }

    resize() {
        const parentCoords = this.checkWindow ? 
            { width: window.innerWidth, height: window.innerHeight } : 
            getElementCoords(this.parent)
        const needResize = this.width !== parentCoords.width || this.height !== parentCoords.height
        if (needResize) {
            this.canvas.width = this.width = parentCoords.width
            this.canvas.height = this.height = parentCoords.height
            this.ratio = window.devicePixelRatio
            this.renderer.setSize(this.width, this.height, false)
            this.renderer.setPixelRatio(this.ratio)
            this.resizing.forEach(f => f())
        }
    }

    render(time) {
        if (!this.isRenderActive) { return }
        this.rendering.forEach(f => f(time))
        this.resize()
    }
}

export default Canvas3d