import React, { useContext, useState, useMemo } from 'react'
import './CalculatorModal.scss'
import { $t } from '@/i18n/i18n'
import Icon from '@/components/UI/Icon/Icon'
import { ModalsContext } from '@/components/Modals/context'
import { modals } from '@/components/Modals/constants'
import CustomRadio from './CustomRadio/CustomRadio'
import CustomRange from './CustomRange/CustomRange'

const CalculatorModal = React.forwardRef(({ ...props }, ref) => {
    const locale = 'components.modals.calculator'
    const { setModal } = useContext( ModalsContext )
    const [ radio, setRadio ] = useState(2)
    const [ months, setMonths ] = useState(18)
    // eslint-disable-next-line
    const seq_per_day = useMemo(() => $t(`${locale}.select.options`)[radio].value, [ radio ])
    const seq_total = useMemo(() => seq_per_day * months * 31, [ seq_per_day, months ])
    // eslint-disable-next-line
    const usd_total = useMemo(() => Math.floor(seq_total * Number($t(`${locale}.footer.value`)), [ seq_total ]))

    return (
        <div { ...props } ref={ref} className="calculator-modal">
            <div className='calculator__header'>
                <h5>
                    <Icon label="calculator" />
                    { $t(`${locale}.title`) }
                </h5>
                <div onClick={() => setModal( modals.NONE )} className='cross'>x</div>
            </div>
            <div className='calculator__select'>
                <h6>{ $t(`${locale}.select.title`) }</h6>
                <div className='select-wrapper'>
                    { $t(`${locale}.select.options`).map((_, i) => (
                        <CustomRadio 
                            onChange={ () => setRadio(i) }
                            checked={ radio === i }
                            key={i}
                            { ..._ }
                        />
                    )) }
                </div>
            </div>
            <div className='calculator__range'>
                <h6>{ $t(`${locale}.range.title`) }</h6>
                <CustomRange
                    value={months}
                    min={0}
                    max={24}
                    step={1}
                    onChange={(value) => setMonths(value)}
                />
            </div>
            <div className='calculator__footer'>
                <h6>{ $t(`${locale}.footer.title`) }</h6>
                <div className='footer__wrapper'>
                    <div className='count'>
                        <p>{ seq_total }</p>
                        <p>
                            <span>{ $t(`${locale}.footer.seq`) }</span>
                            <span>{ $t(`${locale}.footer.coin`) }</span>
                        </p>
                    </div>
                    <div className='divider'></div>
                    <div className='price'>
                        <p>{ usd_total }<span>$</span></p>
                        <p>{ $t(`${locale}.footer.price`) } { $t(`${locale}.footer.value`) }$</p>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default CalculatorModal