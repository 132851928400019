import './assets/index.scss'
import React from 'react'
import ReactDOM from 'react-dom/client'
import ApiContextProvider from "./api/context"
import ModalsContextProvider from "./components/Modals/context"
import Modals from "@/components/Modals/Modals"
import { RouterProvider } from 'react-router-dom'
import { router } from './router'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApiContextProvider>
    <ModalsContextProvider>
      <RouterProvider router={router} />
      <Modals />
    </ModalsContextProvider>
  </ApiContextProvider>
)