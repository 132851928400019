import { createContext, useState, useEffect } from "react"
import { getEthereumPrice } from "./api"

export const ApiContext = createContext()

const ApiContextProvider = ({ children }) => {
    const [ eth, setEth ] = useState(null)
    useEffect(() => {
        getEthereumPrice().then(data => setEth(data))
    }, [])

    const value = { 
        currency: {
            eth: {
                price: eth
            }
        } 
    }

    return (
        <ApiContext.Provider value={value}>
            { children }
        </ApiContext.Provider>
    )
}

export default ApiContextProvider