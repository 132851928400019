import renderer, { setToRender, removeFromRender } from "@/animator/js/renderer"
import { useEffect } from "react"

export const useRender = ( handler, props ) => {
    useEffect(() => {
        const label = props.label || `useRender${Date.now()}`
        setToRender({
            label,
            handler,
            ...props
        })
        return () => removeFromRender(label)
    }, [])
}

export const useRenderer = () => {
    useEffect(() => {
        renderer.startRender()
        renderer.subscribeMouse()

        return () => {
            renderer.stopRender()
            renderer.unsubscribeMouse()
        }
    })
}