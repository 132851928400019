import * as THREE from 'three'
import { utils } from '../utils'
import { layers } from '../constants'

const assetsCount = 16

class Nfts {
    instance
    geometry
    material
    shader

    nftSize = 200

    row = 20
    count = 400

    constructor( scene ) {
        this.geometry = this.generateBufferGeometry()
        this.shader = shader()
        this.material = this.setMaterial(this.shader)
        this.instance = new THREE.Points(this.geometry, this.material)
        this.instance.layers.enable( layers.ENTIRE_SCENE )
        this.instance.position.set(0, 0, 0)
        this.instance.rotation.set(Math.PI / -2.5, 0, 0)
        scene.add( this.instance )
    }

    generateBufferGeometry() {
        const geometry = new THREE.BufferGeometry()

        const pointPositions = []
        const pointSizes = []
        const pointMap = []

        for (let i = 0; i < this.row; i++) {
            for (let j = 0; j < Math.floor(this.count / this.row); j++) {
                let x = 0
                if (j % 2 === 0) {
                    x = (i - Math.ceil(this.row / 2) + 0.5 - 0.5) * 0.3
                } else {
                    x = (i - Math.ceil(this.row / 2) + 0.5) * 0.3
                }

                let y = (j - 0.25 * j / 2) * -0.3

                pointPositions.push(x, y, 0)
                pointSizes.push(this.nftSize)
                pointMap.push( Math.round(utils.rMinMax(0, assetsCount - 1)) )
            }
        }

        // const data = {}
        // pointMap.forEach(index => {
        //     data[index] = typeof data[index] === 'number' ? data[index] + 1 : 0
        // })
        // console.log(data)

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(pointPositions, 3));
        geometry.setAttribute('size', new THREE.Float32BufferAttribute(pointSizes, 1));
        geometry.setAttribute('mapId', new THREE.Float32BufferAttribute(pointMap, 1));
        return geometry
    }

    setMaterial(shader) {
        return new THREE.ShaderMaterial({
            vertexShader: shader.vertexShader,
            fragmentShader: shader.fragmentShader,
            uniforms: shader.uniforms,
            defines: Object.assign({}, shader.defines),
            transparent: true,
            // depthWrite: false,
            // depthTest: false,
            fog: true,
        });
    }

    resize() {
        this.material.uniforms.iResolution.value.x = window.innerWidth * window.devicePixelRatio
        this.material.uniforms.iResolution.value.y = window.innerHeight * window.devicePixelRatio
    }

    render() {
        for( let i = 0; i < this.count; i++ ) {
            const starY = this.geometry.attributes.position.getY(i)
            this.geometry.attributes.position.setY(
                i,
                starY + 0.001
            )

            if ( starY > 0 ) {
                this.geometry.attributes.position.setY(i,
                    -5.25
                )
            }
        }
        this.geometry.attributes.position.needsUpdate = true
    }
}

const shader = () => {
    return {
        uniforms: {
            iMap: { value: [...new Array(assetsCount)].map((_, i) => {
                const texture = new THREE.TextureLoader().load( require(`../../assets/nfts/${i}.png`) )
                texture.flipY = false
                return texture
            }) },
            iTime: { value: 0 },
            iTime2: { value: 0 },
            iShift: { value: new THREE.Vector3() },
            iAlpha: { value: 0.0 },
            iAnimation: { value: new THREE.Vector3() },
            iResolution: {
                value: {
                    x: window.innerWidth * window.devicePixelRatio,
                    y: window.innerHeight * window.devicePixelRatio
                }
            },
            
            fogDensity: { value: 0.00025 }, // не влияет, от глобального дыма пляшем. но передать обязательно
            fogNear: { value: 0 },
            fogFar: { value: 0 },
            fogColor: { value: /*@__PURE__*/ new THREE.Color( 0x000000 ) },
        },
        vertexShader: `
            #include <fog_pars_vertex>
            attribute float mapId;
            attribute float size;
            uniform float iTime;
            uniform vec2 iResolution;
            varying vec2 vUv;
            varying float id;
    
            void main() {
                #include <begin_vertex>
                #include <project_vertex>
                vUv = uv;
                id = mapId;
                vec3 v = position;
                vec4 vpos = modelViewMatrix * vec4(v, 1.);
                gl_PointSize = size * iResolution.y / 1000. / -vpos.z;
                gl_Position = projectionMatrix * vpos;
                #include <fog_vertex>
            }`,
        fragmentShader: `
            #include <fog_pars_fragment>
            uniform sampler2D iMap[${assetsCount}];
            uniform float iAlpha;
            varying vec2 vUv;
            varying float id;

            vec4 getTexColor(float id) {
                if ( id < 0.5 ) {
                    return texture2D( iMap[0], gl_PointCoord );
                }
                if ( id < 1.5 ) {
                    return texture2D( iMap[1], gl_PointCoord );
                }
                if ( id < 2.5 ) {
                    return texture2D( iMap[2], gl_PointCoord );
                }
                if ( id < 3.5 ) {
                    return texture2D( iMap[3], gl_PointCoord );
                }
                if ( id < 4.5 ) {
                    return texture2D( iMap[4], gl_PointCoord );
                }
                if ( id < 5.5 ) {
                    return texture2D( iMap[5], gl_PointCoord );
                }
                if ( id < 6.5 ) {
                    return texture2D( iMap[6], gl_PointCoord );
                }
                if ( id < 7.5 ) {
                    return texture2D( iMap[7], gl_PointCoord );
                }
                if ( id < 8.5 ) {
                    return texture2D( iMap[8], gl_PointCoord );
                }
                if ( id < 9.5 ) {
                    return texture2D( iMap[9], gl_PointCoord );
                }
                if ( id < 10.5 ) {
                    return texture2D( iMap[10], gl_PointCoord );
                }
                if ( id < 11.5 ) {
                    return texture2D( iMap[11], gl_PointCoord );
                }
                if ( id < 12.5 ) {
                    return texture2D( iMap[12], gl_PointCoord );
                }
                if ( id < 13.5 ) {
                    return texture2D( iMap[13], gl_PointCoord );
                }
                if ( id < 14.5 ) {
                    return texture2D( iMap[14], gl_PointCoord );
                }
                if ( id < 15.5 ) {
                    return texture2D( iMap[15], gl_PointCoord );
                }
                return texture2D( iMap[0], gl_PointCoord );
            }

            void main() {
                // float tex = smoothstep(1., .3, length(2. * gl_PointCoord - 1.));
                vec4 texColor = getTexColor(id);
                if (texColor.a < 0.1) { discard; }
                gl_FragColor = vec4(texColor);
                #include <fog_fragment>
            }`
    }
}


export default Nfts