import './slides.scss'
import { $t } from '@/i18n/i18n'
import { useContext, useRef, useMemo } from 'react'
import { ScreenScrollContext } from '@/components/ScreenScroll/ScreenScroll'
import Video from '@/components/UI/VIdeo/Video'
import DelayedImage from '@/components/UI/Image/DelayedImage'
import image from '../assets/4.png'
import imagePl from '../assets/4-placeholder.png'


const Slide4 = () => {
    const { activeId } = useContext( ScreenScrollContext )
    const isVisible = useMemo(() => activeId !== 'game4', [ activeId ])

    return (
        <div className="slide slide_4">
            <div className='content-border'></div>
            <div className='content__wrapper'>

                <div className='wrapper__text'>
                    <h3>{ $t('pages.home.game.list')[3].title }</h3>
                    <div className='text__row'>
                        <p>{ $t('pages.home.game.list')[3].text }</p>
                        {/* <div className='row__image'>
                            <YouTubeVideo/>
                            <Video disabled pauseOn={ isVisible } poster={ '/forest-poster.png' } src={ '/forest.mp4' } width="396" height="296" />
                        </div> */}
                    </div>
                </div>

                <div className='content__image'>
                    <div className='wrapper__image'>
                        <DelayedImage src={ image } placeholder={ imagePl } width="1346" height="895"/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Slide4