import * as THREE from 'three';

class Camera {
    instance
    constructor( scene ) {
        this.instance = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 80 )
        this.instance.position.set( 0, 0, 3 )
        // this.instance.layers.enable(1)
        scene.add( this.instance )
    }

    resize() {
        this.instance.aspect = window.innerWidth / window.innerHeight
        this.instance.updateProjectionMatrix()
    }
}

export default Camera