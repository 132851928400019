import './JoinUs.scss'
import { $t } from '@/i18n/i18n'
import DiscordImage from '../assets/Discord.png'
import BluredDiscordImage from '../assets/BluredDiscord.png'
import DelayedImage from "@/components/UI/Image/DelayedImage"
import Button from "@/components/UI/Button/Button";


const JoinUs = () => {

    return (
        <div className='join-us'>
            <picture>
                <DelayedImage height='170' width='170' className='join-us__image' src={DiscordImage} placeholder={BluredDiscordImage}/>
            </picture>
            {/* width='124' height='124' */}
            <p>
                {$t('pages.myspace.joinus.title')}
            </p>
            <Button label='joinDiscord' link={ $t('components.buttons.discord.link') }>
                {$t('pages.myspace.joinus.button')}
            </Button>
        </div>
    )
}

export default JoinUs