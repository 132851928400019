import './Mint.scss'
import MintNews from './components/MintNews/MintNews'
import Button from '@/components/UI/Button/Button'
import { $t } from '@/i18n/i18n'
import { useRenderer } from '@/animator/js/react/hooks'
import Scene from './components/Scene/Scene'

const Mint = () => {
    useRenderer()
    return (
        <div id="mint" className='mint'>
            <h1>{ $t('pages.mint.title') }</h1>
            <MintNews/>
            <Button label="metamask">{ $t('pages.mint.metamask') }</Button>
            <div className='mint__subinfo'>{ $t('pages.mint.subinfo') }</div>
            <div className='mint__background'>
                <Scene/>
                <div className='blur'></div>
            </div>
        </div>
    )
}

export default Mint