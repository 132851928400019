import './Farming.scss'
import { $t } from '@/i18n/i18n'
import SwitchBox from '@/components/UI/Forms/Switch/SwitchBox'
import Statistics from '../Statistics/Statistics'
import { cards } from '../constants'
import {useEffect, useRef } from 'react'
import EarnedImage from '../Statistics/assets/EarnedImage.png'
import SEQPoolImage from '../Statistics/assets/SEQPoolImage.png'
import FarmTimeImage from '../Statistics/assets/FarmTimeImage.png'
import whiteBaloonImage from '../assets/whiteBaloonImage.png'
import BluredWhiteBaloon from '../assets/BluredWhiteBaloon.png'
import DelayedImage from "@/components/UI/Image/DelayedImage"
import {setToRender, removeFromRender} from '@/animator/js/renderer'
import {getScrollCoordsFromElement, isElementVisible} from '@/animator/js/coords/index'

const Farming = () => {
    const image = useRef()
    const relate = useRef()

    useEffect(() => {
        const label = 'whiteBaloon'
        const delay = 5
        

        setToRender({
            label,
            handler: () => {
                if(!image.current || !relate.current) {return}
                if(!isElementVisible(image.current).partable.y) {return}

                const relateCoord = getScrollCoordsFromElement(relate.current).windowTop.fromTop
                image.current.style.transform = `translate3d(0, ${relateCoord/2}px, 0)`
            },
            delay
        })

        return  () => removeFromRender(label)

    }, [])

    return (
        <div className='farming'>
            <div className='farming__first'>
                <p className='farming__first__title'>
                    { $t('pages.myspace.statistics.title') }
                </p>
                <SwitchBox/>
            </div>
            <div ref={relate} className='farming__cards'>
                <Statistics path={SEQPoolImage} amount='19 324 543' title={ $t('pages.myspace.statistics.seq.title') } numeral={ $t('pages.myspace.statistics.seq.currency')} card={cards.POOL}></Statistics>
                <Statistics path={FarmTimeImage} amount='05:13:12' title={ $t('pages.myspace.statistics.farmtime.title') } numeral='13 days' card={cards.TIME}></Statistics>
                <Statistics path={EarnedImage} amount='12 403' title={ $t('pages.myspace.statistics.earned.title') } numeral={ $t('pages.myspace.statistics.earned.currency')} card={cards.EARNED}></Statistics>
                <DelayedImage ref={image} className='white-ballon' width="265" height="250" src={whiteBaloonImage} placeholder={BluredWhiteBaloon}/>
            </div>
        </div>
    )
}

export default Farming