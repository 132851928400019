import './slides.scss'
import { $t } from '@/i18n/i18n'

import DelayedImage from '@/components/UI/Image/DelayedImage'
import image from '../assets/3.png'
import imagePl from '../assets/3-placeholder.png'

const Slide3 = () => {
    return (
        <div className="slide slide_3">
            <div className='content-border'></div>
            <div className='content__wrapper'>

                <div className='wrapper__text'>
                    <h3>{ $t('pages.home.game.list')[2].title }</h3>
                    <div className='text__row'>
                        <p>{ $t('pages.home.game.list')[2].text }</p>
                    </div>
                </div>

            </div>

            <div className='content__image'>
                <div className='wrapper__image'>
                    <DelayedImage src={ image } placeholder={ imagePl } width="1346" height="895"/>
                </div>
            </div>
        </div>
    )
}

export default Slide3