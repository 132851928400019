import './Statistics.scss'
import cn from 'classnames'
import { $t } from '@/i18n/i18n'
import { useState, React, useEffect, useRef } from 'react'
import Icon from '../../../../components/UI/Icon/Icon'
import { cards } from '../constants'
import Exchange from './Exchange/Exchange'
import CollectButton from '../../../../components/UI/Forms/Buttons/CollectButton/CollectButton'
import Button from "@/components/UI/Button/Button";

const Statistics = ({...props}) => {


    return (
        <div className={cn( 'statistics', props.card === cards.POOL ? '-seq-pool' : '', props.card === cards.TIME ? '-farm-time' : '', props.card === cards.EARNED ? '-earned' : '')}>
            <img alt='' src={props.path} className='statistics__image'/>
            <div className='statistics__information'>
                <div className='information__title'>
                    <p>
                        {props.title}
                    </p>
                </div>
                <div className='information__amount'>
                    <p className='information__amount__number'>
                       {props.amount}
                    </p>
                    <p className='information__amount__currency'>
                        {props.numeral}
                    </p>
                    {props.card === cards.EARNED && <Icon label='important' className='information__amount__image'></Icon>}
                </div>
            </div>
            <div className='statistics__reward'>
                {props.card === cards.EARNED && <Exchange className="exchange-first" main={$t('pages.myspace.statistics.earned.hour')} currency="8,000 SEQ"></Exchange>}
                {props.card === cards.EARNED && <Exchange  currency='±25.39 $' main={$t('pages.myspace.statistics.earned.usd')}></Exchange>}
            </div>
            {props.card === cards.EARNED && <Button label='rewards' className='reward-button'>Collect rewards</Button>}
        </div>
    )
}

export default Statistics