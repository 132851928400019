import './slides.scss'
import { $t } from '@/i18n/i18n'

import DelayedImage from '@/components/UI/Image/DelayedImage'
import firstImage from '../assets/1.png'
import firstPlImage from '../assets/1-placeholder.png'


const Slide1 = () => {
    return (
        <div className="slide slide_1">
            <div className='content-border'></div>
            <div className='content__wrapper'>

                <div className='wrapper__text'>
                    <div className='text__title'>
                        <h2>{ $t('pages.home.game.title') }</h2>
                    </div>
                    <h3>{ $t('pages.home.game.list')[0].title }</h3>
                    <div className='text__row'>
                        <p>{ $t('pages.home.game.list')[0].text }</p>
                        <div className='wrapper__image'>
                            <DelayedImage src={ firstImage } placeholder={ firstPlImage } width="365" height="365"/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Slide1