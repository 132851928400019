import './Background.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LinearBottomImage from './assets/linear-bottom.png'
import LinearTopImage from './assets/linear-top.png'


const LinearBorder = ({ top, bottom }) => {
    if (top) {
        return (
            <div className='linear-border border-top'>
                <LazyLoadImage
                    src={LinearTopImage}
                    width={1920} 
                    height={300}
                    alt=""
                    visibleByDefault={true}
                />
            </div>
        )
    }
    if (bottom) {
        return (
            <div className='linear-border border-bottom'>
                <LazyLoadImage
                    src={LinearBottomImage}
                    width={1920} 
                    height={300}
                    alt=""
                    visibleByDefault={true}
                />
            </div>
        )
    }
    return <></>
}

export default LinearBorder