import DetectKeyboard from './detectKeyboard';
import DetectSwipe from './detectSwipe';
import DetectWheel from './detectWheel';
import DetectShotcuts from './detectShortcuts';

class Detector {
  swipe
  scroll
  keyboard
  shotcuts

  callback

  constructor(dom, callback) {
    if (!dom) { console.error('[Detector]: dom element is required'); return; }
    this.callback = callback || ( () => {} )
    this.swipe = new DetectSwipe(dom, 5, this.controlSwipe.bind(this));
    this.scroll = new DetectWheel(dom, this.controlScroll.bind(this));
    this.keyboard = new DetectKeyboard(dom, this.controlScroll.bind(this));
    this.shotcuts = new DetectShotcuts(dom)
  }

  controlSwipe(props) {
    if (props.direction !== 'down' && props.direction !== 'up') {
      return
    }
    const to = props.direction === 'down' ? 1 : -1
    this.control(to)
  }
  controlScroll(props) {
    const to = props.direction
    this.control(to)
  }
  control(to) {
    if ( !this.shotcuts.pressed.shift ) {
      this.callback(to)
    }
  }

  unmount() {
    this.swipe.destroy();
    this.keyboard.destroy();
    this.shotcuts.destroy();
    this.scroll.unsubscribe();
  }
}

export default Detector
