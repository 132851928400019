import './About.scss'
import Image from '@/components/UI/Image/Image'
import bgImage from './assets/bg.png'
import mascotImage from './assets/mascot.png'
import sequoiaImage from './assets/sequoia.png'
import { $t } from '@/i18n/i18n'

const About = () => {
    return (
        <div className='about'>
            <div className='about__content offset-y'>
                <div className='content__wrapper'>
                    <div className='wrapper__text'>
                        <h2>{ $t('pages.home.about.title') }</h2>
                        <h3>{ $t('pages.home.about.subtitle') }</h3>
                    </div>
                    <div className='wrapper__image'>
                        <Image src={sequoiaImage} width="675" height="675"/>
                    </div>
                </div>

                <div className='content__background'>
                    <Image src={bgImage} width="1440" height="795"/>
                </div>
                <div className='content__mascot'>
                    <Image src={mascotImage} visibleByDefault={true} width="387" height="548"/>
                </div>
            </div>
        </div>
    )
}

export default About