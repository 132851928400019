
import { Outlet } from 'react-router-dom'
import Header from '@/components/Header/Header'
import ScrollProvider from '@/components/ScreenScroll/ScreenScroll'

const Layout = () => {
    return (
        <ScrollProvider duration={750} scrollRoute={'/'}>
            <Header/>
            <Outlet />
        </ScrollProvider>
    )
}

export default Layout