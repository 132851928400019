import "./Main.scss";
import bgImage from './assets/bg.png'
import bgPlaceholderImage from './assets/bg-placeholder.png'
import { $t } from "@/i18n/i18n";
import Icon from "@/components/UI/Icon/Icon";
import Button from "@/components/UI/Button/Button"
import LinearBorder from '@/components/Background/LinearBorder'
import DelayedImage from "@/components/UI/Image/DelayedImage"
import parse from "html-react-parser"

const Main = () => {
  return (
    <div className="main">
      <div className="main__content">
        <p>{ $t('pages.home.main.text') }</p>
        <h1>
          <span className="hidden">{ parse($t('pages.home.main.title')) }</span>
          <Icon label="logo"/>
        </h1>
        <h3>{ parse($t('pages.home.main.subtitle')) }</h3>
        <div className="content__buttons">
          <Button link={$t('components.buttons.whitepaper.link')} label="document">{$t('components.buttons.whitepaper.text')}</Button>
          <Button link={$t('components.buttons.twitter.link')} label="twitter">{$t('components.buttons.twitter.text')}</Button>
        </div>
      </div>
      <div className="main__background">
        <DelayedImage src={bgImage} placeholder={ bgPlaceholderImage } width="1440" height="800" />
        <LinearBorder bottom />
      </div>
    </div>
  );
};

export default Main;
