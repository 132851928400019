import './EmptyCard.scss'
import cn from 'classnames'
import { $t } from '@/i18n/i18n'
import { useState, React } from 'react'

const Card = ({...props}) => {


    


    return (
        <div className='empty-card'>
            <div className='empty-card__image'>

            </div>
            <div className='empty-card__rarity'>
            </div>
            <div className='empty-card__footer'>
                <div className='empty-card__footer__first'>

                </div>
                <div className='empty-card__footer__second'>

                </div>
            </div>
        </div>
    )
}

export default Card