class DetectShotcuts {
    element
    cb

    pressed = {
        shift: false
    }

    constructor(element, cb) {
        this.element = element
        this.cb = cb
        this.start()
    }

    start() {
        this.element.addEventListener('keydown', this.keydown)
        this.element.addEventListener('keyup', this.keyup)
    }

    destroy() {
        this.element.removeEventListener('keydown', this.keydown)
        this.element.removeEventListener('keyup', this.keyup)
    }

    keydown(e) {
        const key = e.keyCode
        if ( !this.pressed.shift ) { this.pressed.shift = (key === 16) }
    }
    keydown = this.keydown.bind(this)

    keyup(e) {
        const key = e.keyCode
        if ( this.pressed.shift ) { this.pressed.shift = !(key === 16) }
    }
    keyup = this.keyup.bind(this)
}

export default DetectShotcuts