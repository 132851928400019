import { rarity } from "../../constants"
import LegendaryImage from '../Card/assets/legendary 1 7.png'
import EpicImage from '../Card/assets/Epic.png'
import RareImage from '../Card/assets/Rare.png'
import CommonImage from '../Card/assets/common 3 2.png'
import BluredLegendaryImage from '../Card/assets/BluredLegendaryImage.png'
import BluredEpicImage from '../Card/assets/BluredEpicImage.png'
import BluredRareImage from '../Card/assets/BluredRareImage.png'
import BluredCommonImage from '../Card/assets/BluredCommonImage.png'

export const cardsMock = [
    [
        '24124', '50', LegendaryImage, rarity.LEGENDARY, BluredLegendaryImage
    ],
    [
        '02201', '35', EpicImage, rarity.EPIC, BluredEpicImage
    ],
    [
        '01201', '15', CommonImage, rarity.COMMON, BluredCommonImage
    ],
    [
        '01201', '25', RareImage, rarity.RARE, BluredRareImage
    ],
    [
        '01201', '15', CommonImage, rarity.COMMON, BluredCommonImage
    ],
    [
        '01201', '15', CommonImage, rarity.COMMON, BluredCommonImage
    ],
    [
        '01201', '25', RareImage, rarity.RARE, BluredRareImage
    ],

]
