import './Features.scss'
import { $t } from '@/i18n/i18n'
import Image from '@/components/UI/Image/Image'
import bgImage from './assets/bg.png'
import hutImage from './assets/hut.png'
import cloudsImage from './assets/clouds.png'

import icon01Image from './assets/01.png'
import icon02Image from './assets/02.png'
import icon03Image from './assets/03.png'
import icon04Image from './assets/04.png'
import icon05Image from './assets/06.png'
import icon06Image from './assets/05.png'

import LinearBorder from '@/components/Background/LinearBorder'

const data = [
    icon01Image, icon02Image, icon03Image,
    icon04Image, icon05Image, icon06Image
]



const Features = () => {
    return (
        <div className='features'>
                <div className='features__content'>
                    <h2><span>{ $t('pages.home.features.title') }</span></h2>
                    <div className='content__list'>
                        { $t('pages.home.features.list').map((_, i) => (
                            <div key={i} className='list__item'>
                                <div className='item__preview'>
                                    <Image src={data[i]} width="64" height="64" />
                                </div>
                                <h4>{ _.title }</h4>
                                <p>{ _.text }</p>
                            </div>
                        )) }
                    </div>
                </div>

                <div className='features__background'>
                    <div className='background__forest'>
                        <Image src={bgImage} width="1440" height="796" />
                    </div>
                    <div className='background__clouds'>
                        <Image src={cloudsImage} width="1440" height="796" />
                    </div>
                    <div className='background__hut'>
                        <div className='hut__wrapper'>
                            <Image src={hutImage} width="720" height="942" />
                        </div>
                    </div>
                    <LinearBorder top />
                    <LinearBorder bottom />
                </div>
        </div>
    )
}

export default Features