import React, { useCallback, useEffect, useState } from "react"

const DelayedImage = React.forwardRef(({ src, placeholder, delay, ...props }, ref) => {
    const [imgSrc, setSrc] = useState(placeholder || src);

    const onLoad = useCallback(() => {
        setSrc(src)
    }, [src])

    useEffect(() => {
        const img = new Image()

        setTimeout(() => {
            img.src = src
            img.addEventListener("load", onLoad)
        }, delay || 300)

        return () => img.removeEventListener("load", onLoad)
    }, [src, onLoad])

    return (
        <span>
            <img ref={ref} {...props} src={imgSrc} alt="" />
        </span>
    )
})

export default DelayedImage