import { useState, useEffect } from "react"

const useWHeight = () => {
    const [ wHeight, setWHeight ] = useState( window.innerHeight || 0 )
    const [ wWidth, setWWidth ] = useState( window.innerWidth || 0 )
    useEffect(() => {
        let startTime = performance.now()
        let isRender = true
        requestAnimationFrame(function render(time) {
            if (time - startTime > 30) {
                if (wHeight !== window.innerHeight || wWidth !== window.innerWidth) {
                    setWHeight( window.innerHeight )
                    setWWidth( window.innerWidth )
                }
                startTime = performance.now()
            }
            if (isRender) { requestAnimationFrame(render) }
        })
        return () => isRender = false
    }, [])
    return [ wHeight, setWHeight ]
}

export { useWHeight }