import * as THREE from 'three'

import { layers } from './constants'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
import { SavePass } from 'three/examples/jsm/postprocessing/SavePass.js';

import { FinalPass } from './common/finalpass'
// import { AddPass } from './common/addpass';

class Composer {
    renderer
    camera
    scene

    bloomComposer
    finalComposer

    finalPass

    constructor( renderer, scene, camera ) {
        this.renderer = renderer
        this.camera = camera
        this.scene = scene
        this.init()
    }

    init() {
        const renderScene = new RenderPass( this.scene, this.camera );
        this.finalPass = new ShaderPass(FinalPass)

        this.bloomComposer = new EffectComposer( this.renderer )
        this.bloomComposer.addPass( renderScene )
        const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.7, .3, 0 )
        this.bloomComposer.addPass( bloomPass )
        // const gammaCorrectionPass = new ShaderPass( GammaCorrectionShader )
        // this.bloomComposer.addPass( gammaCorrectionPass )
        this.bloomComposer.addPass( new SavePass() )


        this.finalComposer = new EffectComposer( this.renderer )
        this.finalComposer.addPass( renderScene )
        this.finalPass.uniforms.bloomTexture.value = this.bloomComposer.renderTarget2.texture
        this.finalComposer.addPass( this.finalPass )

    }

    resize() {
        this.bloomComposer.setSize( window.innerWidth, window.innerHeight )
        this.finalComposer.setSize( window.innerWidth, window.innerHeight )
    }

    composer_render() {
        this.camera.layers.set( layers.BLOOM_SCENE )
        this.bloomComposer.render()
        this.camera.layers.set( layers.ENTIRE_SCENE )
        this.finalComposer.render()
    }

    render() {
        this.composer_render()
    }
}

export default Composer