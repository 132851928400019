import './Scene.scss'
import { useRef, useEffect } from 'react'
import { setToRender, removeFromRender } from '@/animator/js/renderer'
import Canvas from './canvas'

const Scene = () => {
    const parent = useRef()
    const canvas = useRef()

    useEffect(() => {
        const scene = new Canvas(parent.current, canvas.current)
        const label = 'scene'
        const dom = document.body
        if (dom) { dom.addEventListener('mousemove', scene.mousemove) }
        if (dom) { dom.addEventListener('click', scene.mouseclick) }
        setToRender({
            label,
            handler: () => scene.render(),
            delay: 0
        })
        return () => { 
            removeFromRender(label)
            if (dom) { dom.removeEventListener('mousemove', scene.mousemove) }
            if (dom) { dom.removeEventListener('click', scene.mouseclick) }
        }
    }, [])

    return (
        <div ref={parent} className='space'>
            <canvas ref={canvas} width={1920} height={1080}/>
        </div>
    )
}

export default Scene