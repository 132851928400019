const screens = {
    MAIN: 'main',
    ABOUT: 'about',
    FEATURES: 'features',
    GAME: 'game',
    GENERATION: 'generation',
    GENESIS: 'genesis',
    PARK: 'park',
    TOKENOMICS: 'tokenomics',
    ROADMAP: 'roadmap',
    TEAM: 'team',
    FAQ: 'faq',
    FOOTER: 'socials'
}

export { screens }