const FinalPass = {
    uniforms: {
      iTime: { value: 0 },
      tDiffuse: { value: null },
      bloomTexture: { value: null },
      haloTexture: { value: null }
    },
    vertexShader: `
      varying vec2 vUv;
          void main()	{
                vUv = uv;
                gl_Position = vec4( position, 1.0 );
          }`,
    fragmentShader: `
      uniform float iTime;
      uniform sampler2D tDiffuse;
      uniform sampler2D bloomTexture;
      uniform sampler2D haloTexture;
      varying vec2 vUv;
  
      void main() {
        vec2 uv = 2. * vUv - 1.;
        gl_FragColor = vec4(texture2D(bloomTexture, vUv).xyz + texture2D(tDiffuse, vUv).xyz + texture2D(haloTexture, vUv).xyz, 1.);
      }`
  }
  
  export { FinalPass };