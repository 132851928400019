class DetectWheel {
    element
    cb

    constructor(element, cb) {
        this.element = element
        this.cb = cb
        this.subscribe()
    }

    subscribe() {
        this.element.addEventListener('wheel', this.listener)
    }

    unsubscribe() {
        this.element.removeEventListener('wheel', this.listener)
    }

    listener(e) {
        if (Math.abs(e.deltaY) < 9) { return }
        if (typeof this.cb === 'function') { this.cb({
            direction: e.wheelDeltaY / Math.abs(e.wheelDeltaY) * -1,
        }) }
        document.dispatchEvent(new CustomEvent("scroll", {
            bubbles: true, 
            detail: {
                direction: e.wheelDeltaY / Math.abs(e.wheelDeltaY) * -1,
            }
        }))
    }
    listener = this.listener.bind(this)
}

export default DetectWheel