import './FAQ.scss'
import { $t } from '@/i18n/i18n'
import Accordion from '../../../../components/UI/Accordion/Accordion'
import redBaloonImage from '../assets/redBaloonImage.png'
import UFOImage from '../assets/UFOImage.png'
import shamanImage from '../assets/shamanImage.png'
import houseImage from '../assets/houseImage.png'
import CollectButton from '../../../../components/UI/Forms/Buttons/CollectButton/CollectButton'
import FAQDonut from './FAQDonut/FAQDonut'
import DelayedImage from "@/components/UI/Image/DelayedImage"
import BluredRedBaloon from '../assets/BluredRedBaloon.png'
import BluredUFOImage from '../assets/BluredUFOImage.png'
import BluredShamanImage from '../assets/BluredShamanImage.png'
import BluredHouseImage from '../assets/BluredHouseImage.png'
import {setToRender, removeFromRender} from '@/animator/js/renderer'
import {getScrollCoordsFromElement, isElementVisible} from '@/animator/js/coords/index'
import { useRef, useEffect } from 'react'


const FAQ = () => {

    const baloonImageRef = useRef()
    const UFOImageRef = useRef()
    const shamanImageRef = useRef()
    const houseImageRef = useRef()
    const relate = useRef()

    useEffect(() => {
        const label = 'redBaloon'
        const delay = 5

        

        setToRender({
            label,
            handler: () => {

                if(!baloonImageRef.current || !shamanImageRef.current || !UFOImageRef.current || !relate.current) {return}
                // if(!isElementVisible(baloonImageRef.current).partable.y) {return}

                const relateCoord = getScrollCoordsFromElement(relate.current).windowTop.fromTop
                baloonImageRef.current.style.transform = `translate3d(0, ${relateCoord/2}px, 0)`
                shamanImageRef.current.style.transform = `translate3d(0, ${relateCoord/2}px, 0)`
                UFOImageRef.current.style.transform = `translate3d(0, ${relateCoord/2}px, 0)`

            },
            delay
        })

        return  () => removeFromRender(label)

    }, [])


    return (
        <div className='FAQ' ref={relate}>
            <p className='FAQ__title'>{$t('pages.myspace.FAQ.title')}</p>
            <div className='FAQ__list'>
                    { $t('pages.myspace.FAQ.questions').map((_, i) => (
                        <div key={i} className='FAQ__list__item'>
                            {/* <h4>{ _.name }</h4> */}
                            <div className='questions'>
                                { _.list.map(accordion => (
                                    <Accordion key={accordion.title} title={accordion.title} body={accordion.body}>{accordion.image ? <FAQDonut/> : ''}</Accordion>
                                )) }
                            </div>
                        </div>
                    )) }

            </div>
            <div className='FAQ__shade'></div>
            <DelayedImage ref={baloonImageRef} width='329' src={redBaloonImage} placeholder={BluredRedBaloon} height='143' className='FAQ__red-baloon'/>
            <DelayedImage ref={UFOImageRef} width='246' src={UFOImage} placeholder={BluredUFOImage} height='326' className='FAQ__UFO'/>
            <DelayedImage ref={shamanImageRef} width='567' src={shamanImage} placeholder={BluredShamanImage} height='717' className='FAQ__shaman-image'/>
            <DelayedImage ref={houseImageRef} width='460' src={houseImage} placeholder={BluredHouseImage} height='627' className='FAQ__house-image'/>
            {/* <Accordion key='ffffsa' title='ffffsa' body='ff' /> */}
        </div>
    )
}

export default FAQ