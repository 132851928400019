import './Team.scss'
import Background from '@/components/Background/Background'
import LinearBorder from '@/components/Background/LinearBorder'
import background1Image from '../Tokenomics/assets/background-1.png'
import { $t } from '@/i18n/i18n'
import Image from '@/components/UI/Image/Image'

const list = [
    {
        id: -1,
        image: require('./assets/alexandr.png')
    },
    {
        id: 0,
        image: require('./assets/andrew.png')
    },
    {
        id: 1,
        image: require('./assets/dmitry.png')
    },
    {
        id: 2,
        image: require('./assets/vladimir.png')
    },
    {
        id: 3,
        image: require('./assets/paul.png')
    },
    {
        id: 4,
        image: require('./assets/dzianis.png')
    },
    {
        id: 5,
        image: require('./assets/alex.png')
    },
    {
        id: 6,
        image: require('./assets/sion.png')
    },
    {
        id: 7,
        image: require('./assets/eugen.png')
    },
    {
        id: 8,
        image: require('./assets/biologist.png')
    },
    {
        id: 9,
        image: require('./assets/arkadiy.png')
    },
    {
        id: 10,
        image: require('./assets/simion.png')
    },
    {
        id: 11,
        image: require('./assets/vladimirDefi.png')
    }
]

const PersonName = ({ data }) => {
    if (data.link) {
        return <h5><a href={data.link} target="_blank" rel='noreferrer'>{ data.name }</a></h5>
    }
    return <h5>{ data.name }</h5>
}

const Team = () => {
    return (
        <div className='team'>
            <div className='team__content'>
                {/* <h2>{ $t('pages.home.team.title') }</h2> */}
                <div className='team__list'>
                    {$t('pages.home.team.list').map((_, i) => {
                        const data = Object.values(list).find(item => item.id === _.id)
                        return (
                            <div key={i} className='list__item'>
                                <div className='item__avatar'>
                                    <Image src={ data.image } width="200" height="200" />
                                </div>
                                <PersonName data={_} />
                                <p>{ _.position }</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='team__background'>
                <Background image={ background1Image } className='background_1' />
                <LinearBorder bottom />
            </div>
        </div>
    )
}

export default Team