import './CustomRadio.scss'

const CustomRadio = ({ name, value, velocity, checked = false, onChange, className = '', id = Date.now(), ...props }) => {
    return (
        <div { ...props } className={`calculator-radio ${className}`}>
            <input onChange={onChange} name={id} type="radio" checked={checked} />
            <div className="calculator-radio__content">
                <p className="name">{ name }</p>
                <p className="value">{ value } { velocity }</p>
            </div>
        </div>
    )
}

export default CustomRadio