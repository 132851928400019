import './Footer.scss'
import { $t } from '@/i18n/i18n'
import Icon from '../../../../components/UI/Icon/Icon'

const Footer = () => {

    const images = [
        'mMedia',
        'instagramMedia',
        'redditMedia',
        'telegramMedia',
        'facebookMedia',
        'inMedia'
    ]

    return (
        <div className='myspace__footer'>
            <Icon 
            className="myspace__footer__logo" 
            label="logo" 
            />
            <div className='myspace__footer__media'>
                {/* {images.map((_, idx) => <Icon label={_} className='myspace__footer__media__image'></Icon>)} */}
                {$t('pages.myspace.footer.socials').map((_, i) => (
                    <a key={i} href={_.link} target="_blank" rel="noreferrer">
                        <Icon label={_.icon} />
                    </a>
                ))}
            </div>

            <p>{$t('pages.myspace.footer.title')}</p>
        </div>
    )
}

export default Footer