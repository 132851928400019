import * as THREE from 'three';
import { utils } from '../utils'
import { layers } from '../constants';

const assets = [
    require('../../assets/star-blue.png'),
    require('../../assets/star-green.png'),
    require('../../assets/star-white.png'),
    require('../../assets/star-yellow.png'),
]

class Stars {
    instance
    material
    geometry

    count = 100
    parallelDataAttribute


    constructor( scene ) {
        this.geometry = this.generateBufferGeometry()
        this.shader = shader()
        this.parallelDataAttribute = [...new Array(this.count)].map(() => ({ 
            velocity: 0,
            acceleration: utils.rMinMax(0.000001, 0.0001)
        }))
        this.material = this.setMaterial(this.shader)
        this.instance = new THREE.Points(this.geometry, this.material)
        this.instance.layers.enable( layers.BLOOM_SCENE )
        this.instance.position.set(0, 0, 0)
        scene.add( this.instance )


        // звезды на фоне
    }

    generateBufferGeometry() {
        const geometry = new THREE.BufferGeometry()

        const pointPositions = [];
        const pointSizes = [];
        const pointMap = []
        const alphaDelta = []


        for (let i = 0; i < this.count; i++) {
            let x = utils.rMinMax(-3, 3);
            let y = utils.rMinMax(-3, 3);
            let z = utils.rMinMax(-100, 10);
            pointPositions.push(x, y, z);
            pointSizes.push(10 + 50 * Math.random());
            pointMap.push( Math.round(utils.rMinMax(0, assets.length - 1)) );
            alphaDelta.push( utils.rMinMax(0, 1) );
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(pointPositions, 3));
        geometry.setAttribute('size', new THREE.Float32BufferAttribute(pointSizes, 1));
        geometry.setAttribute('mapId', new THREE.Float32BufferAttribute(pointMap, 1));
        geometry.setAttribute('alphaDelta', new THREE.Float32BufferAttribute(alphaDelta, 1));
        return geometry
    }

    setMaterial(shader) {
        return new THREE.ShaderMaterial({
            vertexShader: shader.vertexShader,
            fragmentShader: shader.fragmentShader,
            uniforms: shader.uniforms,
            defines: Object.assign({}, shader.defines),
            transparent: true,
            depthWrite: false,
            depthTest: false,
            blending: THREE.AdditiveBlending,
        });
    }

    move() {
        // this.instance.rotation.z += 0.0003
        for( let i = 0; i < this.count; i++ ) {
            const starZ = this.geometry.attributes.position.getZ(i)
            this.geometry.attributes.position.setZ(
                i,
                starZ - this.parallelDataAttribute[i].velocity
            )
            this.parallelDataAttribute[i].velocity = this.parallelDataAttribute[i].velocity < 0.001 ? this.parallelDataAttribute[i].velocity + this.parallelDataAttribute[i].acceleration : this.parallelDataAttribute[i].velocity

            if ( starZ < -80 ) {
                this.geometry.attributes.position.setXYZ(i,
                    2 * Math.random() - 1,
                    2 * Math.random() - 1,
                    50
                )
                this.parallelDataAttribute[i].velocity = 0
            }
        }
        this.geometry.attributes.position.needsUpdate = true
    }

    resize() {
        this.material.uniforms.iResolution.value.x = window.innerWidth * window.devicePixelRatio
        this.material.uniforms.iResolution.value.y = window.innerHeight * window.devicePixelRatio
    }

    render() {
        this.move()
    }
}

const shader = () => {
    return {
        uniforms: {
            iMap: { value: assets.map(img => new THREE.TextureLoader().load(img)) },
            iTime: { value: 0 },
            iTime2: { value: 0 },
            iShift: { value: new THREE.Vector3() },
            iAlpha: { value: 0.0 },
            iAnimation: { value: new THREE.Vector3() },
            iResolution: {
                value: {
                    x: window.innerWidth * window.devicePixelRatio,
                    y: window.innerHeight * window.devicePixelRatio
                }
            },

            fogDensity: { value: 0.00025 }, // не влияет, от глобального дыма пляшем. но передать обязательно
            fogNear: { value: 0 },
            fogFar: { value: 0 },
            fogColor: { value: /*@__PURE__*/ new THREE.Color( 0x000000 ) },
        },
        vertexShader: `
            #include <fog_pars_vertex>
            attribute float mapId;
            attribute float size;
            uniform float iTime;
            uniform vec2 iResolution;
            varying float transparency;
            varying vec2 vUv;
            varying float id;
    
            void main() {
                #include <begin_vertex>
                #include <project_vertex>
                vUv = uv;
                id = mapId;
                vec3 v = position;
                v = 3. * (2. * fract(v) - 1.);
                vec4 vpos = modelViewMatrix * vec4(v, 1.);
                vec2 t = vec2(1);
                vec2 q = vec2(length(position.xy)-t.x,position.z);
                transparency = step(length(v), 3.);
                gl_PointSize = size * iResolution.y / 1000. / -vpos.z;
                gl_Position = projectionMatrix * vpos;
                #include <fog_vertex>
            }`,
        fragmentShader: `
            #include <fog_pars_fragment>
            uniform sampler2D iMap[${assets.length}];
            uniform float iAlpha;
            varying float transparency;
            varying vec2 vUv;
            varying float id;

            vec4 getTexColor(float id) {
                if ( id < 0.5 ) {
                    return texture2D( iMap[0], gl_PointCoord );
                }
                if ( id < 1.5 ) {
                    return texture2D( iMap[1], gl_PointCoord );
                }
                if ( id < 2.5 ) {
                    return texture2D( iMap[2], gl_PointCoord );
                }
                if ( id < 3.5 ) {
                    return texture2D( iMap[3], gl_PointCoord );
                }
            }

            void main() {
                float tex = smoothstep(1., .3, length(2. * gl_PointCoord - 1.));
                vec4 texColor = getTexColor(id);
                if (texColor.a < 0.1) { discard; }
                gl_FragColor = vec4(texColor.xyz, tex * transparency );
                #include <fog_fragment>
            }`
    }
}

export default Stars