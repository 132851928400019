import * as THREE from 'three';
import Canvas3d from '@/animator/js/presets/canvas3d'

import Composer from './composer'
import Camera from './components/camera'
import Stars from './components/stars'
import Planets from './components/planets'
import Nfts from './components/nfts'
import BG from './components/bg'
import Mouse from './components/mouse'

class Canvas extends Canvas3d {
    composer

    camera

    stars
    planets
    nfts
    bg
    mouse

    constructor(parent, canvas) {
        super(parent, canvas)

        this.camera = new Camera( this.scene )
        this.composer = new Composer( this.renderer, this.scene, this.camera.instance )
        this.stars = new Stars( this.scene )
        this.planets = new Planets( this.scene )
        this.nfts = new Nfts( this.scene )
        this.bg = new BG( this.scene )
        this.mouse = new Mouse( this.scene )

        this.scene.fog = new THREE.Fog(0x000000, 0, 2.5);

        this.toRender(() => {
            this.stars.render()
            this.planets.render()
            // this.renderer.render( this.scene, this.camera.instance )
            this.nfts.render()
            this.bg.render()
            this.mouse.render()
            this.composer.render()
        })

        this.toResize(() => {
            this.camera.resize()
            this.stars.resize()
            this.nfts.resize()
            this.bg.resize()
            this.mouse.resize()
            this.composer.resize()
        })
    }

    mousemove() {
        this.mouse.mousemove()
    }
    mouseclick() {
        this.mouse.mouseclick()
    }
    mousemove = this.mousemove.bind(this)
    mouseclick = this.mouseclick.bind(this)

}

export default Canvas