import * as React from "react";
import { createBrowserRouter, createHashRouter } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import Home from "./views/Home/Home";
import Mint from "./views/Mint/Mint";
import MySpace from './views/MySpace/MySpace'

const router = createHashRouter([
    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: "",
                element: <Home />,
            },
            {
                path: "mint",
                element: <Mint />
            },
            {
                path: "my-space",
                element: <MySpace />
            }
        ],
    },
]);

export { router }