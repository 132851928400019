import './MintNews.scss'
import Tween from '@/animator/js/tween/tween'
import Ease from '@/animator/js/tween/easing'
import { setToRender, removeFromRender } from '@/animator/js/renderer'
import { useEffect, useRef } from 'react'
import { $t } from '@/i18n/i18n'

const MintNews = ({ duration = 4000 }) => {
    const dom = useRef({})
    const count = $t('pages.mint.news').length
    const state = {
        start: 0,
        list: []
    }
    useEffect(() => {
        state.start = performance.now()
        state.list = [...new Array(count)].map((_, i) => 1)
        const label = 'MintNews'
        setToRender({
            label,
            handler: (time) => {
                if ( time - state.start > duration ) {
                    Object.values(dom.current).forEach((el, i) => {
                            const from = (state.list[i] - 1) * 100
                            const to = state.list[i] * 100
                            state.list[i] = (state.list[i] >= count - 1 + i) ? i : state.list[i] + 1
                            Tween.to(from, to,
                                {
                                    duration: duration / 20,
                                    ease: Ease.InOutQuartic,
                                    onChange(context) {
                                        if (dom.current[i]) {
                                            dom.current[i].style.transform = `translateX(${-1 * context.value}%)`
                                        }
                                    },
                                    onComplete(context) {
                                        if (dom.current[i]) {
                                            dom.current[i].style.transform = `translateX(${-1 * context.value}%)`
                                        }
                                    }
                                }
                            )
                    })
                    state.start = performance.now()
                }
            },
            delay: 10
        })
        return () => removeFromRender(label)
    }, [])

    return (
        <div className='mint-news'>
            { $t('pages.mint.news').map((text, i) => (
                <p ref={(r) => dom.current[i] = r} key={i}>{ text }</p>
            )) }
        </div>
    )
}

export default MintNews