import * as THREE from 'three';
import { utils } from '../utils'
import { layers } from '../constants';

const assets = [
    require('../../assets/mars.png'),
]

class Planets {
    instance
    geometry
    material
    move = 0.0001
    constructor( scene ) {
        this.geometry = new THREE.PlaneGeometry(2.2, 2.2)
        this.material = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            transparent: true,
            map: new THREE.TextureLoader().load( assets[0] ),
            depthTest: false,
            depthWrite: false,
            fog: false
        })
        this.instance = new THREE.Mesh( this.geometry, this.material )
        this.instance.position.set(2.5, 1, 0)
        this.instance.layers.enable( layers.ENTIRE_SCENE )
        scene.add( this.instance )
    }

    render() {
        if ( this.instance.position.y > .55 || this.instance.position.y < .45  ) { this.move *= -1 }
        this.instance.position.y -= this.move
    }
}

export default Planets